// FORM RADIO
@import "core";

@include respond-to(base-inline) {
  :where(.tc_form__radio){position:absolute;opacity:0;
    & ~ .tc_form__label{display:block;position:relative;padding:var(--spacing-2) 0 var(--spacing-2) var(--spacing-32);font-size:var(--font-14);line-height:var(--line-130);-webkit-user-select:none;user-select:none;
      &::before,&::after{content:"";position:absolute;z-index:1;border-radius:50%;}
      &::before{top:0;left:0;width:var(--spacing-20);height:var(--spacing-20);border:var(--spacing-1) solid var(--color-dark-500);background-color:var(--color-light);}
      &::after{top:var(--spacing-4);left:var(--spacing-4);width:var(--spacing-12);height:var(--spacing-12);border:var(--spacing-1) solid transparent;opacity:0;transition:opacity var(--transition-faster);background-color:var(--color-900 );}
    }
    &:checked ~ .tc_form__label::after{opacity:1;}
    &:focus-visible ~ .tc_form__label::before{outline:var(--spacing-2) solid var(--color-focus);outline-offset:var(--spacing-2);}
    &[disabled]{pointer-events:none;
      & ~ .tc_form__label{pointer-events:none;
        &::before{background-color:var(--color-200);}
      }
    }
    &[required] ~ .tc_form__label{text-indent:var(--spacing-8);
      &::before{content:"*";text-indent:var(--spacing-24);font-weight:var(--font-bold);color:var(--color-600);}
    }
    &.tc_form__radio--with-error{
      & ~ .tc_form__label{
        &::before{border-color:var(--color-error);}
      }
    }
  }
}

@include respond-to(hover) {
  :where(.tc_form__radio):not(.tc_form__radio--with-error){
    &:hover ~ .tc_form__label{
      &::before{transition:border var(--transition-normal);border-color:var(--color-dark-600);}
    }
  }
}
